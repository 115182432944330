<template>
  <div class="vertical_video_warp">
    <div id="vxgVideo">
      <div class="avoid_sliding" @click="pause"></div>
    </div>
  </div>
</template>
<script>
import HlsJSPlayer from 'xgplayer-hls.js';
import { mapGetters } from 'vuex';
import { getLocalItem } from '@/utils/longStorage';
import { playCount, getPlayVideo } from '@/api/video';
import { Toast } from 'vant';
import { addVideoHistory } from '@/utils';

export default {
  props: {
    videoInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
      wCount: 0, //免费观看次数
      isCan: false, //是否能观看 true 能观看，false不能观看 (只有会员视频才能判断)
      nowDate: new Date(),
      playTimer: null,
      playTime: 0,
      via: 0,
      isReport: false,
    };
  },
  computed: {
    ...mapGetters({
      baseUrl: 'baseUrl',
    }),
  },
  created() {
    this.playCount();
    if (this.$route.query.via) {
      this.via = parseInt(this.$route.query.via);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const player = (this.player = new HlsJSPlayer({
        id: 'vxgVideo',
        url: this.getPath(this.videoInfo.sourceURL),
        fluid: true,
        playsinline: true,
        videoInit: true,
        loop: true,
        autoplay: true,
        controls: true,
        lang: 'zh-cn',
        ignores: ['fullscreen', 'i18n', 'play', 'volume', 'definition'],
        allowPlayAfterEnded: true,
        allowSeekAfterEnded: true,
        closeVideoPreventDefault: true,
        closeVideoStopPropagation: true,
        closeInactive: true,
        enableVideoDbltouch: true,
      }));
      player.on('playing', () => {
        this.playing();
        this.$store.commit('video/SET_CURRENT_VIDEO_OBJ', this.videoInfo);
      });
      player.on('pause', () => {
        this.pauses();
      });
      player.on('waiting', () => {
        this.pauses();
      });
      player.on('timeupdate', () => {
        try {
          let totalTime = player.video.duration;
          let currentTime = player.video.currentTime;
          let report = 15;
          if (totalTime > 300) {
            report = totalTime * 0.1;
          }
          if (totalTime < 15) {
            report = totalTime;
          }
          let reportData = {
            dataType: 1,
            videoId: this.videoInfo.id,
            videoTitle: this.videoInfo.title,
          };
          if (!this.playCheck() && player.hasStart) {
            player.pause();
            if (this.videoInfo.originCoins == 0) {
              this.$emit('vipBuyShow');
            } else {
              this.$emit('goldBuyShow');
            }
            if (!this.isReport) {
              this.$bus.$emit('dataBuriedEvent', reportData);
              this.isReport = true;
            }
          } else {
            if (!this.isReport && currentTime >= report) {
              this.$bus.$emit('dataBuriedEvent', reportData);
              this.isReport = true;
            }
          }
        } catch (e) {
          player.pause();
          Toast('播放异常，请重新进入');
        }
      });
    },
    //当视频滑走的时候调用 用作统计
    async getPlayInfo() {
      let req = {
        publisher: this.videoInfo.publisher.uid,
        videoID: this.videoInfo.id,
        beginTime: this.nowDate,
        endTime: new Date(),
        longer: this.playTime,
        via: this.via || 1,
        playWay: this.isCan ? (this.wCount ? 0 : 1) : 2,
      };
      if (this.videoInfo.tags && this.videoInfo.tags.length > 0) {
        req.tagID = this.videoInfo.tags[0].id;
      }
      this.$Api(getPlayVideo, req);
      clearInterval(this.playTimer);
      this.playTimer = null;
      this.playTime = 0;
    },
    playing() {
      if (!this.playTimer) {
        this.playTimer = setInterval(() => {
          this.playTime++;
        }, 1000);
      }
    },
    pauses() {
      clearInterval(this.playTimer);
      this.playTimer = null;
    },
    //获取免费次数
    async playCount() {
      let res = await this.$Api(playCount, { vid: this.videoInfo.id });
      if (res.code === 200) {
        this.isCan = res.data.isCan;
        this.wCount = res.data.watchCount;
        this.$emit('watchCountData', { wCount: res.data.watchCount, isCan: res.data.isCan });
      }
    },
    getPath(u) {
      const token = getLocalItem('token');
      const newUrl = this.baseUrl + 'vid/h5/m3u8/' + u + '?token=' + token;
      return newUrl;
    },
    //视频购买成功
    succcess() {
      this.isGoldbuyShow = false;
      this.videoInfo.vidStatus.hasPaid = true;
      this.player.play();
      this.$store.dispatch('user/getWallet');
      Toast('购买成功');
    },
    pause() {
      this.player.pause();
    },

    //检测视频是否能播放
    playCheck() {
      try {
        if (this.player.video.currentTime < this.videoInfo.freeTime) {
          return true;
        }
        if (this.videoInfo.originCoins === 0) {
          //会员视频
          if (this.isCan) {
            return true;
          }
          return false;
        } else {
          // 金币视频
          if (this.videoInfo.vidStatus.hasPaid) {
            return true;
          }
          return false;
        }
      } catch (e) {
        return false;
      }
    },
  },
  beforeDestroy() {
    addVideoHistory(this.videoInfo);
    if (this.player) {
      this.getPlayInfo();
      this.player.destroy(true); //销毁播放器
    }
    this.player = null;
  },
  deactivated() {
    if (this.player?.hasStart) {
      this.player.pause();
    }
  },
};
</script>
<style scoped lang="scss">
.vertical_video_warp {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  background: #000000;
  align-items: center;
  /deep/ .xgplayer-progress-played {
    background: #ff678f;
  }
  :deep(.xgplayer-progress) {
    top: 20px;
  }
  :deep(.xgplayer-start),
  :deep(.xgplayer-controls) {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    bottom: 3px;
  }
  :deep(.xgplayer-progress-btn) {
    background-color: #ff678f;
  }
  :deep(.xgplayer-time-current:after) {
    content: ' ';
  }
  :deep(.xgplayer-progress) {
    left: 48%;
    right: 0;
    width: 70%;
    transform: translate(-50%, 0);
  }
  :deep(.xgplayer-time) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
  :deep(.xgplayer-time-current)::after {
    display: none;
  }
  #vxgVideo {
    width: 100%;
    height: 100% !important;
    position: absolute;
    padding-top: 0 !important;
    top: 0;
    left: 0;
    position: relative;
    :deep(.xgplayer-enter-spinner) {
      width: 50px;
      height: 50px;
    }
    .avoid_sliding {
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent;
      top: 0;
    }
  }
}
</style>
